import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate,  } from 'react-router-dom'
import { collection, doc, documentId, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Header from '../components/Header'
import { auth, db } from '../firebaseConfig'
import '../App.css'
import SiteCard from '../components/SiteCard'
import PodcastCard from '../components/PodcastCard'
import { SiteWithId } from '../types/business'
import { onAuthStateChanged } from 'firebase/auth'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => {
    return new URLSearchParams(search)
  }, [search])
}

export function queryCategory(category: string | null) {
  if (category && category !== 'favorites') {
    const postsRef = collection(db, `sites`)
    return query(postsRef, where('categories', 'array-contains', category), orderBy('createdAt', 'desc'))
  }
  const sitesRef = collection(db, 'sites')
  return query(sitesRef, orderBy('createdAt', 'desc'))
}

export function MainPage() {
  const queryParams = useQuery()
  const navigate = useNavigate()
  const [favorites, setFavorites] = useState<string[] | undefined>(undefined)

  useEffect((() => {
    const unsubscribe = onAuthStateChanged(auth, async () => {
          
      if (!auth.currentUser?.uid) {
        setFavorites([])
        return
      }
      
      const favoritesQuery = await getDoc(doc(db, `users/${auth.currentUser.uid}/saved/favorites`))
      const favoritesRawData = favoritesQuery.data()
      
      if (!favoritesRawData) {
        setFavorites([])
        return
      }
      
      // console.log(Object.entries(favoritesRawData).filter(v => v[1].favorited === true))
      // favoritesRawData.filter((v: {favorited: string}) => v.favorited)
      setFavorites(Object.entries(favoritesRawData).filter(v => v[1].favorited === true).map((v => v[0])))
    })

    return () => unsubscribe()
  }), [])

  const location = useLocation()

	useEffect(() => {
		const bodyClasses = document.body.classList
		bodyClasses.remove('h-full')
		bodyClasses.remove('overflow-hidden')
		window.document.body.style.backgroundImage = ''
	}, [location])
  
  const [sites, setSites] = useState<SiteWithId[]>([])

  useEffect(() => {(async () => {
    const sitesQuery = queryCategory(queryParams.get('category')?.toString() ?? null)
    setSites((await getDocs(sitesQuery)).docs.map(d => {return {...d.data(), id: d.id} as SiteWithId}))
  })()
  }, [queryParams])
  
  
  // const [inView, setInView] = useState(false)


  // useEffect(() => {
  //   const isInView = (intersectionObserverEntry: IntersectionObserverEntry[]) => {
  //     setInView(intersectionObserverEntry[0].isIntersecting)
  //     console.log(intersectionObserverEntry[0].target)
  //     return
  //     // let rect = cardRef?.current?.getBoundingClientRect() ?? undefined
  //     // if (rect) {
  //     //   if (
  //     //     rect.top >= 0 &&
  //     //     rect.left >= 0 &&
  //     //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
  //     //     rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  //     //   ) {
  //     //     setInView(true)
  //     //     return
  //     //   }
  //     // } 
  //     // setInView(false)
  //   }

  //   if (sites.length > 0) {
  //     // cardRef.current.addEventListener('', attachObserver)
  //     const observer = new IntersectionObserver(isInView, {
  //       root: cardRef.current,
  //       rootMargin: "0px",
  //       threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  //     })
  //     observer.observe(cardRef.current ?? document.createElement('div'))
  //   }

  //   return () => {
  //     cardRef.current?.removeEventListener('scroll', attachObserver)
  //   }

  // }, [sites])


  return (
    <>
      <span className='snap w-full' id='snap'>
        <Header />
        <article className='w-full my-20 font-Fira'>
          <div className='flex flex-col items-center'>

            {/* <PodcastCard /> */}

            {(sites && (queryParams.get('category')?.toString() === 'favorites' ? <>
              {sites.map(post => {
                const data = post as unknown as SiteWithId

                if ((favorites && (favorites.indexOf(post.id) !== -1)) ? true : false) {
                  return <SiteCard
                    businessData={data}
                    isPinned={true}
                    setFavorites={setFavorites}
                    key={data.id}
                  />
                }
                return <>
                </>
              })}
            </> : <>
              {sites.map(post => {
                const data = post as unknown as SiteWithId
                // const category = queryParams.get('category')?.toString()
                // if (category && !(data?.categories && data?.categories.includes(category))) {
                //   alert('hey')
                //   return
                // }
                
                return <>
                  <SiteCard
                    businessData={data}
                    isPinned={(favorites && (favorites.indexOf(post.id) !== -1)) ? true : false}
                    setFavorites={setFavorites}
                    key={data.id}
                  />
                </>
              })}
            </>)) 
            }
          </div>
        </article>
      </span>   
    </>
  )
}
