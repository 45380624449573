import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebaseConfig'
import Header from '../components/Header'
import { SignInButton, SignOutButton } from '../components/Authentication'

function LoginPage() {
  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {
    if (!user)
      return

    window.location.pathname = '/'
    // window.location.reload()
  }, [user])

  return (<>
    <Header />
    
    <article className='w-100vw bg-black min-h-screen font-Fira pt-20 text-white flex flex-col'>
      <img
        src='/assets/logo.png'
        className='max-w-sm self-center w-11/12'
      />
      <h2 className='text-4xl text-center mb-2'>
        webzep.net
      </h2>
      <h2 className='text-3xl text-center mb-2'>
        1 click sign in to save websites <br />
      </h2>
      {!loading && (user ? <>
        <SignOutButton className='w-max self-center bg-white text-black hover:bg-black hover:text-white border-white border-2  ' />
      </> :
        <SignInButton className='w-max self-center bg-white text-black hover:bg-black hover:text-white border-white border-2 ' />
      )}
    </article>
  </>)
}

export default LoginPage