import Link from '@tiptap/extension-link'
import { generateHTML } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useState, useEffect, useRef } from 'react'
import LazyLoad from 'react-lazyload'
import { Site, SiteWithId } from '../types/business'
import ExternalButton from './ExternalButton'
import NeumorphicCard from './NeumorphicCard'
import { VoteButtons } from './VoteButtons'
import { Voted } from './Voted'
import { ErrorBoundary } from 'react-error-boundary'
import { auth, db } from '../firebaseConfig'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { signInWithGoogle } from './Authentication'

interface Props {
  businessData: SiteWithId
  setFavorites: React.Dispatch<React.SetStateAction<string[] | undefined>>
  isPinned: boolean
}

function SiteCard({ businessData, setFavorites, isPinned }: Props) {
  const { title, categories, description, image, video, link, businessLink, subtitle, upvotes, downvotes, subtext, hasPoll, embeddedWebsite, id } = businessData
  
  // const cardRef = useRef<HTMLSpanElement>(null)
  // const [inView, setInView] = useState(true)

  const bodyRef = useRef<HTMLDivElement>(null)
  const [showFront, setShowFront] = useState(true)
  const [showFlipAnimation, setShowFlipAnimation] = useState(false)
  const [vote, setVote] = useState<0 | 1 | null>(null)
  
  useEffect(() => {
    const target = bodyRef.current
    if (target && target.getElementsByTagName('a').length > 0) {
      for (let i = 0; i < target.getElementsByTagName('a').length; i++) {
        target.getElementsByTagName('a')[0].onclick = (e) => e.stopPropagation()
      }
    }
  }, [showFlipAnimation])

  // useEffect(() => {
  //   const isInView = (intersectionObserverEntry: IntersectionObserverEntry[]) => {
  //     // setInView(intersectionObserverEntry[0].isIntersecting)
  //     console.log(intersectionObserverEntry)

  //     // let rect = intersectionObserverEntry[0].boundingClientRect ?? undefined
  //     // if (rect) {  
  //     //   if (
  //     //     rect.top >= 0 &&
  //     //     rect.left >= 0 &&
  //     //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
  //     //     rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  //     //   ) {
  //     //     setInView(true)
  //     //     return
  //     //   }
  //     // } 
  //     // setInView(false)
  //   }

  //   if (cardRef.current) {
  //     // cardRef.current.addEventListener('scrollend', console.log('hello world'))
  //     const observer = new IntersectionObserver(isInView, {
  //       root: cardRef.current,
  //       // rootMargin: "0px",
  //       threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  //     })
  //     observer.observe(cardRef.current ?? document.createElement('div'))
  //   }

  //   return () => {
  //     // cardRef.current?.removeEventListener('scroll', attachObserver)
  //   }

  // }, [cardRef.current])

  const [height, setHeight] = useState(200)

  return (<>
    <span
      className={`duration-150 md:my-2 w-full p-0 my-20 max-w-2xl md:max-h-screen ${showFlipAnimation && 'animate-card-flip'}`}
    >
      <NeumorphicCard>

        {showFront ? (<>
          <div
            className='w-full relative'
            onClick={(e) => {
              if (showFlipAnimation)
                return  // prevent double click
              e.stopPropagation()
              // setShowFlipAnimation(true)
              // setTimeout(() => {
              //   setShowFront(false)
              // }, 250)
              // setTimeout(() => {
              //   setShowFlipAnimation(false)
              // }, 500)
            }}
          >
            {embeddedWebsite && (
              <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <LazyLoad style={{maxHeight: '65dvh', width: '100%', aspectRatio: '3/4'}} height={height} unmountIfInvisible={true} scrollContainer={'#snap'}>
                  <iframe 
                    src={embeddedWebsite ?? ''} 
                    className='rounded-xl mx-auto text-white object-cover w-full max-h-[65dvh] aspect-[3/4] bg-white border-2'
                    // alt={title}
                    draggable={false}
                  />
                </LazyLoad>
              </ErrorBoundary>
            )}
            
            {image && (
              <LazyLoad offset={100} height={height} unmountIfInvisible={false} scrollContainer={'#snap'}>
                <img 
                  src={image} 
                  className='rounded-t-xl mx-auto text-white object-cover w-full max-h-[80vh]'
                  alt={title}
                  onLoad={(el) => setHeight(el.currentTarget.height + 12 + 16)}
                  draggable={false}
                />
              </LazyLoad>
            )}
        
            {video && (
              <LazyLoad offset={100} height={height} unmountIfInvisible={true} scrollContainer={'#snap'}>
                <video
                  src={video}
                  width={'100%'}
                  height={'100%'}
                  controls={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  className='rounded-3xl mx-auto my-2 text-white max-h-[80vh]'
                  onLoadedData={(el) => setHeight(el.currentTarget.height + 16)}
                />
              </LazyLoad>
            )}

            {/* floats over image */}
            {/* <div className='absolute top-0 left-0 w-full h-full object-cover overflow-hidden rounded-[2.5rem]'> */}
            <div className='w-full flex flex-col items-center sm:h-full'>
              {/* <h3 className='text-white font-medium text-3xl mt-4 py-1 px-2 rounded-md'>
                {title}
              </h3> */}

              <span className='flex justify-stretch w-full'>
                <h3 className='text-white font-medium text-xl ml-4 mt-1 mr-auto rounded-md justify-end'>

                  {categories && categories.map((v, i, a) => <>
                    
                    <a href={`/?category=${v}`} className='hover:underline'>
                      {`${i === 0 ? '' : ' '}` + v[0].toUpperCase() + v.substring(1).toString() + `${i !== (a.length - 1) ? ',' : ''}`}
                    </a>

                  </>)}

                </h3>

                <button
                  className={`font-medium text-xl hover:underline text-white \${isPinned ? 'text accent-primary' : 'text accent-primary/50'} font-Fira px-2 rounded-2xl ml-auto py-0 my-0`}
                  onClick={async (e) => {
                    e.stopPropagation()

                    if (auth.currentUser?.uid !== undefined) {
                      console.log('a')
                      await setDoc(doc(db, `users/${auth.currentUser.uid}/saved/favorites`), {
                        [id]: {
                          favoritedAt: serverTimestamp(),
                          favorited: !isPinned
                        }
                      },
                        { merge: true }
                      )
                      console.log('b')
                      
                      setFavorites((favorites) => isPinned ? favorites?.filter((v) => v !== id) : favorites?.concat([id]))
                      
                    } else {
                      window.location.pathname = '/login'
                    }
                  }}
                >
                  {isPinned ? 'Saved' : 'Save'}
                </button>
              </span>
              
                <a
                  className='text-white hover:underline px-4 mt-2 font-medium text-xl md:text-2xl mx-auto text-center'
                  href={link}
                  target='_blank'
                >
                  {subtitle}
                </a>

              {/* </span> */}
              <p className='text-gray-300 mb-4 mt-auto py-1 px-2 rounded-md w-max max-w-[90%]'>
                {subtext}
              </p>

            </div>
            {/* </div> */}
          </div>
          </>) : (
            <>
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  if (showFlipAnimation)
                    return  // prevent double click
                  setShowFlipAnimation(true)
                  setTimeout(() => {
                    setShowFront(true)
                  }, 250)
                  setTimeout(() => {
                    setShowFlipAnimation(false)
                  }, 500)
                }}
              >
                  
                <h1 className='text-black font-medium text-2xl md:text-3xl mx-auto text-center'>
                  {title}
                </h1>
              
                <a
                  className='text-black font-semibold text-xl md:text-2xl mx-auto text-center'
                  href={link}
                  target='_blank'
                >
                  {subtitle}
                </a>
              
                <h3 className='text-black font-medium text-xl md:text-2xl mx-auto text-center'>
                  {subtext}
                </h3>

                {/* <p className='text-accent-primary mx-auto text-center text-lg w-11/12 font-normal'>
                  {description}
                </p> */}
                <div 
                  className='mt-[10%] text-black mx-auto text-lg w-11/12 font-normal whitespace-pre-wrap [overflow-wrap:break-word] description'
                  ref={bodyRef}    
                  dangerouslySetInnerHTML={{
                    __html: ((description && typeof description !== 'string') ? generateHTML(description, [StarterKit, Link]) : '')
                  }}
                >

                </div>

                {hasPoll && <>
                  <div className='mx-auto w-max'>    
                    <VoteButtons
                      setVote={setVote}
                      vote={vote}
                    />  
                  </div>

                  {vote !== null && (
                    <Voted
                      vote={vote}
                      upvotes={upvotes}
                      downvotes={downvotes}
                    />
                  )}
                </>}
              <span className='flex w-full justify-evenly my-4'>
                {link && (
                  <ExternalButton
                    href={link}
                    text='Listen to Episode'
                  />
                )}
                
                {businessLink && (
                  <ExternalButton
                    href={businessLink}
                    text='View Site'
                  />
                )}
              </span> 

            </span>
          </>)}              
      </NeumorphicCard>
    </span>
  </>)
}

export default SiteCard