import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth"
// import  { collection, doc, setDoc, getDoc, serverTimestamp} from 'firebase/firestore'

import { auth } from '../firebaseConfig'

import React from 'react'

const provider = new GoogleAuthProvider()

export async function signedIn(cred: any) {
    // const uid = cred.user.uid
    // const coll = collection(db, 'users')
    // const userRef = doc(db, 'users', uid)
    // const userDocSnap = await getDoc(userRef)
    
    // if (!userDocSnap.exists()) {
    //   return setDoc(doc(coll, `${uid}`), {
    //     displayName: cred.user.displayName,
    //     email: cred.user.email,
    //     photoURL: cred.user.photoURL,
    //     createdAt: serverTimestamp(),
    //     uid: uid,
    //     votes: [],
    //   })
    // }
}

export function signInWithGoogle() {
    signInWithPopup(auth, provider).then((cred) => {
        signedIn(cred)
    }).catch((e) => {
        // console.log(e.code)
    })
}
  
function SignInButton(props: { className?: string }) {
    return (
        <button className={`flex flex-col text-center mt-4 btn font-Fira hover:bg-hype-200/0 my-auto ${props.className}`} onClick={signInWithGoogle}>
            <p className="mt-1 text-lg">
                Sign In
            </p>
        </button>
    )
}

function SignOutButton(props: { className?: string }) {
    return (
        <button className={`text-center mt-4 btn font-Fira hover:bg-hate-200/0 my-auto ${props.className}`} onClick={() => signOut(auth)}>


            {/* <button
              onClick={() => window.location.href = '/history'}
              className='btn bg-accent-primary hover:bg-hype-200 w-max'
            >
              History
            </button> */}
        
            {/* <p className="mt-1 text-lg"> */}
                Sign Out
            {/* </p> */}
        </button>
    )
}

export { SignInButton, SignOutButton }