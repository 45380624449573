import React, { useEffect, useRef } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../firebaseConfig'
import { SignInButton, signInWithGoogle, SignOutButton } from './Authentication'

function Header() {
  const headerRef = useRef<HTMLHeadingElement>(null)
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const categories = [
    ['Business', 'business'],
    ['Deals', 'deals'],
    ['Food & Drink', 'fooddrink'],
    ['Free Stuff', 'freestuff'],
    ['Fun', 'fun'],
    ['Games', 'games'],
    ['Health', 'health'],
    ['Hollywood', 'hollywood'],
    ['Interesting', 'interesting'],
    ['Lifestyle', 'lifestyle'],
    ['Local', 'local'],
    ['Media', 'media'],
    ['Music', 'music'],
    ['News', 'news'],
    ['Nostalgia', 'nostalgia'],
    ['Politics', 'politics'],
    ['Productivity', 'productivity'],
    ['Shopping', 'shopping'],
    ['Sports', 'sports'],
    ['Streaming', 'streaming'],
    ['Tech', 'tech'],
    ['Travel', 'travel'],
    ['Useful', 'useful']
  ].sort()
  
  // useEffect(() => {
  //   let prevScrollpos = window.pageYOffset

  //   function handleScroll() {
  //     let currentScrollPos = window.pageYOffset
  //     if (headerRef.current) {
  //       if (prevScrollpos > currentScrollPos) {
  //         headerRef.current.style.top = "0"
  //       } else {
  //         headerRef.current.style.top = -headerRef.current.offsetHeight + "px"
  //       }
  //     }
  //     prevScrollpos = currentScrollPos
  //   }

  //   window.addEventListener('scroll', handleScroll)

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll)
  //   }
  // }, [])
  return (<>
      <header ref={headerRef} className="flex flex-row mb-4 bg-black/20 backdrop-blur-[2px] fixed w-full z-50 duration-500 font-Fira justify-center">
        <a className="my-auto sm:ml-4 ml-1 text-center text-lg w-min text-white" href={`${window.location.origin}`}>
          <img
            className="max-h-14 h-full lg:max-w-none md:max-w-md sm:max-w-sm max-w-xs sm:w-full object-contain object-left"
            src="/assets/logo.png"
            alt="Logo"
          />
            Home
        </a>

      <span className='sm:ml-auto flex'>
        <a
          className='flex flex-col text-center ml-4  mt-4 text-white hover:cursor-pointer'
          // href={'/?category=favorites'}
          onClick={() => {
            if (auth.currentUser?.uid === undefined) {
              signInWithGoogle()
            } else {
              document.location.assign('/?category=favorites')
            }
          }}
        >        
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem" fill='transparent' stroke='white' strokeWidth={2} className='self-center'>
            <path d="M19 21l-7-5-7 5V5c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v16z"/>
          </svg>

          <p className='mt-2 text-lg'>
          Saved
          </p>


        </a>

          <div className='flex flex-col text-center mx-2 mt-4'>        
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem" fill='transparent' stroke='white' strokeWidth={1} className='self-center'>
              <rect x="3" y="3" width="8" height="8" rx="1" ry="1"/>
              <rect x="13" y="3" width="8" height="8" rx="1" ry="1"/>
              <rect x="3" y="13" width="8" height="8" rx="1" ry="1"/>
              <rect x="13" y="13" width="8" height="8" rx="1" ry="1"/>
            </svg>


            <select 
              className={`ml-auto mt-2 bg-accent-primary/0 text-center text-white hover:bg-hype-100/0 rounded-lg text-lg \${isMobile && 'w-[4rem] text-3xl'} `}
              onChange={(e) => navigate(e.target.value ? `/?category=${e.target.value}` : '/')}
            >
              <option value='' className='select-option'>
              {/* {isMobile ? '☰' : 'Category'} */}
                Categories
            </option>
              {categories.map((category) => (
                <option
                  value={category[1]}
                  className='select-option'
                >
                  {category[0]}
                </option>
              ))}
          </select>


        </div>
        
        <div className='flex flex-col my-auto w-max mr-4 ml-0 mt-4'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem" fill="transparent" stroke="white" stroke-width="2" className="self-center">
                <circle cx="12" cy="8" r="4"/>
                <path d="M4 20c0-4 4-7 8-7s8 3 8 7"/>
          </svg>
          {!loading && (user ? <>
            {/* <div className='flex flex-col text-center mr-4 mt-4'>        
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem" fill="transparent" stroke="white" stroke-width="2" className="self-center">
                <circle cx="12" cy="8" r="4"/>
                <path d="M4 20c0-4 4-7 8-7s8 3 8 7"/>
              </svg> */}

              {/* <button
                onClick={() => window.location.href = '/history'}
                className='btn bg-accent-primary hover:bg-hype-200 w-max'
              >
                History
              </button> */}
          
              <SignOutButton className='w-max mr-2 mt-0 ml-0' />
          </> :
            <SignInButton className='w-max mr-2 mt-0 ml-0' />
          )}
        </div>
      </span>
    </header>
  </>)
}

export default Header